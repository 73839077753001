<template>
    <!-- 专业管理 -->
    <div class="mod-config">
        <el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
            <div class="title">专业设置</div>
            <div class="line"></div>
            <el-row style="margin-bottom: 5px;">
                <el-col :span="pageSpan">
                    <el-button v-preventReClick class="btn" type="primary" size="small"
                        @click="addOrUpdateHandle()">新增</el-button>
                </el-col>
            </el-row>
            <div class="line"></div>
            <el-form ref="form" :model="form" inline label-width="80px" label-position="left">
                <el-row
                    style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
                    <el-col :span="12" style="margin-left:20px">
                        <el-form-item label="专业名称" class="selFormItem">
                            <el-input class="selSearchInput" type="input" v-model="majorName" placeholder="请输入内容"
                                clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" class="selBtnGroup" style="margin-top:5px">
                        <el-button v-preventReClick class="btn" type="primary" size="small"
                            @click="selGetDataList()">查询</el-button>
                        <el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-row>
            <div>
                <el-card style="margin-top: -25px; height: 530px;" body-style="height:490px; padding: 0;">
                    <el-row style="height: 100%; margin-bottom: 10px;">
                        <el-col :span="24" style="height: 100%;">
                            <div class="box">
                                <el-table height="470" :row-class-name="tableRowClassName" ref="dataListTable"
                                    class="dataListTable" :data="dataList" header-align="center" border
                                    :cell-style="{ padding: '0px' }" header-cell-class-name="dataListTHeader"
                                    style="margin-bottom: 20px;">
                                    <el-table-column type="index" label="NO." width="60" align="center">
                                        <template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
                                        }}</template>
                                    </el-table-column>
                                    <el-table-column prop="majorCode" label="专业代码" align="center">
                                    </el-table-column>
                                    <el-table-column prop="majorName" label="专业名称" align="center">
                                    </el-table-column>
                                    <el-table-column prop="sort" label="排序" align="center">
                                    </el-table-column>
                                    <el-table-column label="操作" width="420" align="center">
                                        <template slot-scope="scope">
                                            <el-button v-preventReClick type="text" size="small"
                                                @click="addOrUpdateHandle(scope.row.recId)">修改</el-button>
                                            <el-button v-preventReClick type="text" size="small"
                                                v-if="scope.row.sysFlag == 0"
                                                @click="deleteHandle(scope.row.recId)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <el-pagination class="my-pagination" @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle" :current-page="pageIndex"
                                :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                                layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </el-row>
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="dataList"></add-or-update>
    </div>
</template>
<script>
import AddOrUpdate from './subjectinfo-add-or-update'
import $common from "@/utils/common.js"
export default {
    data() {
        return {
            showSearch: false,
            showDownload: false,
            showAdd: false,
            showEdit: false,
            showDelete: false,
            showCheck: false,
            showArchive: false,
            showRowDownload: false,
            pageSpan: 12,
            addOrUpdateVisible: false,
            form: {},
            totalCount: 0,
            //当前页码
            pageIndex: 1,
            // 显示序号
            pageIndex2: 1,
            //当前记录数
            pageSize: 20,
            //总页数
            totalPage: 0,
            dataList: [],
            majorName: '',
        };
    },
    components: {
        AddOrUpdate,
    },
    mounted() {
        this.getDataList();
        this.pageIndex2 = 1;
    },
    methods: {
        //重置
        resetFormData() {
            this.majorName = '';
            this.pageIndex = 1;
            this.pageIndex2 = 1;
            this.getDataList();
        },
        downloadHandle(url, fileName) {
            if (this.$store.state.showTestFlag) {
                window.open(url)
            } else {
                this.global.downLoadFile(url, fileName + '.docx');
            }
        },
        getDataList() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectMajorInfo/list",
                method: 'get',
                params: this.$http.adornParams({
                    limit: this.pageSize,
                    page: this.pageIndex,
                    workNo: $common.getItem("workNo"),
                    majorName: this.majorName,
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataList = data.body.majorList;
                    this.totalPage = data.body.totalCount;
                    this.pageIndex2 = this.pageIndex
                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.pageIndex2 = 1
                }
            })
        },
        selGetDataList() {
            this.pageIndex = 1;
            this.getDataList();
        },
        //删除
        deleteHandle(id) {
            this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http({
                    url: this.$store.state.httpUrl + "/business/projectMajorInfo/delete/" + id,
                    method: "post",
                    data: {},
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList()
                            }
                        })
                    } else {
                        this.$message.error(data.msg)
                    }
                });
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.limit = val
            this.page = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        tableRowClassName({ row, rowIndex }) {
            let color = ''
            for (let item of this.tableSelVal) {
                if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
            }
            return color
        },
        siteContentViewHeight() {
            var height = this.$store.state.common.documentClientHeight - 160;
            return { height: height + 'px' }
        },
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            })
        },
        presetHandle() {
            this.userPresetVisible = true;
            this.$nextTick(() => {
                this.$refs.userPreset.init();
            })
        },
        tableRowClassName({ row, rowIndex }) {
            return $common.tableRowClassName({ row, rowIndex });
        },
    }
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
    font-size: 14px;
    color: #1c1c1c;
    display: inline-block;
    width: 80px;
}

.my-pagination {
    margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
    // background-color: #d3e2f4 !important;
    font-size: 15px;
    padding: 2px 0;
}

::v-deep(.selSearchInput .el-input__inner) {
    width: 150px !important;
    height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
    font-size: smaller;
}
</style>
